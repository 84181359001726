import { useState, useContext, useEffect } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import Loader from 'react-loader-spinner'
import { useRouter } from 'next/router'
import { useMixpanel } from 'react-mixpanel-browser'

import {
  OnboardHeader,
  OnboardLayout,
  CardContainer,
  TextInput,
  Button,
  Alert,
  ShopifyStoreAuthorizedModal,
} from '../components'
import { GlobalContext } from '../context/GlobalContext'
import api from '../services/api'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [didMount, setDidMount] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [showShopifyStoreAuthorizedModal, setShowShopifyStoreAuthorizedModal] =
    useState(false)
  const globalContext = useContext(GlobalContext)
  const router = useRouter()
  const mixpanel = useMixpanel()

  if (!mixpanel.config) {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
    }
  }

  useEffect(() => {
    api.getCsrfToken().catch(globalContext.handleFetchError)
  }, [])

  useEffect(() => {
    if (router.isReady) {
      const isShopifyAppStoreAuthorizedRedirect =
        !!router.query?.hmac && !!router.query?.shop
      if (isShopifyAppStoreAuthorizedRedirect) {
        /**
         * if this is a redirect from shopify it means the user installed
         * the sunsett shopify app on the shopify app store and we need to authorize it
         */
        const shopifyStoreSlug = router.query.shop.substr(
          0,
          router.query.shop.indexOf('.myshopify'),
        )

        document.location.href = `https://${shopifyStoreSlug}.myshopify.com/admin/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_SHOPIFY_API_KEY}&scope=read_customers,read_products,read_customers,read_orders,read_locations,read_shipping,read_fulfillments&redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/shopify-auth&state=UNAUTHENTICATED`
      } else if (globalContext.user && globalContext.user.id) {
        router.replace(`/workspaces`)
      }

      if (router.query?.shopifystoreauthorized) {
        setShowShopifyStoreAuthorizedModal(true)
        router.replace(document.location.pathname)
      }
    }
  }, [router.query])

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors([])
    setIsWorking(true)

    api
      .login(email, password)
      .then((response) => {
        globalContext.updateUser(response.data)
        if (mixpanel.config) {
          mixpanel.identify(response.data.user.email)
          mixpanel.track('Login')
        }
        if (router.query?.invitationId) {
          router.push(`/invitation/${router.query.invitationId}/respond`)
        } else if (router.query?.next) {
          router.push(router.query.next)
        } else {
          router.push('/workspaces')
        }
      })
      .catch((e) => {
        if (e.response?.status === 419) {
          globalContext.logout()
        }
        if (e.response?.data?.errors) {
          setErrors(Object.values())
        } else if (e.response?.data?.message) {
          setErrors([e.response.data.message])
        } else {
          globalContext.handleFetchError(e)
        }
        setIsWorking(false)
      })
  }

  return (
    <div>
      <Head>
        <title>Login | Sunsett</title>
      </Head>
      <OnboardHeader isLoginScreen />
      <OnboardLayout sidebarText="All of Your Data in One Place">
        <div className="flex flex-1 items-center flex-col mt-10 md:mt-20">
          <h1 className="text-left mb-7 sm:mb-10 text-3xl font-serif">
            Sign In To Your Account
          </h1>
          {router.query?.invitationId && (
            <Alert text="Before you can respond to an invitation, you must login or register." />
          )}
          <CardContainer classes="relative overflow-hidden">
            <form className="w-124 max-w-full relative" onSubmit={handleSubmit}>
              <TextInput
                label="Email"
                placeholder="julie@acme.com"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="-mt-4 mb-8">
                <Link href="/forgot-password">
                  <a className="text-teal-4 text-sm underline">
                    Forgot your password?
                  </a>
                </Link>
              </div>
              <div className="mt-4">
                {!!errors.length && (
                  <Alert type="danger" text={errors.join(' ')} />
                )}
              </div>
              <div className="flex justify-end">
                <Button text="Sign In" disabled={!email || !password} />
              </div>
            </form>
            {isWorking && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-dark-50">
                <Loader type="TailSpin" height="72" width="72" color="white" />
              </div>
            )}
          </CardContainer>
        </div>

        <ShopifyStoreAuthorizedModal
          isOpen={showShopifyStoreAuthorizedModal}
          setIsOpen={setShowShopifyStoreAuthorizedModal}
        />
      </OnboardLayout>
    </div>
  )
}
